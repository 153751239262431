import {
    Route,
    Redirect,
    useLocation
  } from 'react-router-dom';
  
  function PrivateRoute({ children, isAuthenticated, exemptPaths = [], ...rest }) {
    const location = useLocation();
    const isExempt = exemptPaths.some((path) =>
      new RegExp(`^${path.replace(":collectionId", "[^/]+")}$`).test(location.pathname)
    );
  
    if (isAuthenticated || isExempt) {
      return <Route {...rest}>{children}</Route>;
    }
  
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  }
  
  
  export default PrivateRoute;
  