import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import './addToCollection.scss';
import { PurpleButtonSmall } from '../../buttons/buttons';
import {PopupTitle} from '../../title/title';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { getCollections, addWordsToCollection, findCollections, getRecentlyCollectionsReq, findAllCollectionsFromClasses } from '../../../service/requests';

import { closePopup } from '../../../actions/popup';
import { closeSelectionWindow } from '../../../actions/selectionWindow';
import { setCollections } from '../../../actions/collections';
import { openNotification } from '../../../actions/notification';

import CircularProgress from '@mui/material/CircularProgress';


const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
   closeSelectionWindow: () => {
      return dispatch(closeSelectionWindow());
   },
   setCollections: (payload) => {
      return dispatch(setCollections(payload));
   },
   openNotification: (payload) => {
      return dispatch(openNotification(payload));
   }
});


const AddToCollection = (props) => {

   const {
      info,
      closePopup,
      closeSelectionWindow,
      setCollections,
      openNotification
   } = props;

   const words = info.words;
   const currentCollectionId = info.collectionId;
   const [allCollections, setAllCollections] = useState([]);
   const [error, setError] = useState(false);
   const [selectedCollectionName, setSelectedCollectionName] = useState();
   const [isLoading, setIsLoading] = useState(false);
   const [isCollectionLoading, setIsCollectionLoading] = useState(false);


   const getRecentCollections = async () => {
      setIsCollectionLoading(true)
      const collections = await getRecentlyCollectionsReq().catch(console.log);
      if(collections){
         let filteredCollections = collections.data.collections.filter(x=> x._id != currentCollectionId);
         setAllCollections(filteredCollections)
         setIsCollectionLoading(false)
      }
   }
   useEffect(() => {
      getRecentCollections();
    }, [])

    useEffect(() => {
      if(selectedCollectionName) {
         setError(false);
      }
    }, [selectedCollectionName])


   const onChangeAutocomplete = (value) => {
      if (value) {
         setSelectedCollectionName(value.name)
      } else {
         setSelectedCollectionName()
      }
   }

   const onChangeCollectionInput = async (e) => {
      if (e.target.value.length > 0) {
         setIsCollectionLoading(true)



         const promises = [
            findCollections(e.target.value),
            findAllCollectionsFromClasses(e.target.value)
         ];

         const [resOwnCollections, resCollectionsFromClasses] = await Promise.all(promises).catch(console.log);

         const res = [...resCollectionsFromClasses.data.collections, ...resOwnCollections.data.collections];
         let collections = res.filter(x=> x._id != currentCollectionId);




         // const resCollections = await findCollections(e.target.value);
         // console.log('RES COLLECTIONS', resCollections.data.collections);

         setAllCollections(collections)

         setSelectedCollectionName(e.target.value)
         setIsCollectionLoading(false)

      } else {
         setSelectedCollectionName()
         // getRecentCollections()
      }
   }
   
   const checkWord = () => {
      if (allCollections.filter(x => x.name === selectedCollectionName).length > 0) return true;
   }

   const onSave = async () => {
      let isValid = checkWord();
      if (isValid) {
            
         setIsLoading(true)
         closeSelectionWindow()
         
         setError(false);
         const collection = allCollections.find((x) => x.name === selectedCollectionName);
         const wordsId = words.map(word => word._id);
         
         try {
            await addWordsToCollection(collection._id, { words: wordsId })
            const resCollections = await getCollections().catch(err => { console.log(err)});
            setCollections({ collections: resCollections.data.collections })
            setIsLoading(false)
            closePopup()
            openNotification({text: `Words added to collection "${selectedCollectionName}"`, notificationType: 'success' });
         }
         catch (error) {
            console.log(error)
            openNotification({text: `Something went wrong`, notificationType: 'error' });
         }
      } else {
         setError(true)
      }
   }

   return (
      <section className="my-6 mx-5 md:mx-20 relative editCollection">
         <PopupTitle title="Add to Collection"/>

         <div className="flex flex-col mt-3">

         {isCollectionLoading && <CircularProgress style={{position: 'absolute', zIndex: '100', top: '57px', right: '30px'}} size={12} className="mr-2 text-purple dark:text-white"/>}

            <Autocomplete
               open={true}
               id="combo-box-demo"
               value={selectedCollectionName}
               options={allCollections}
               noOptionsText={<p className='text-dark dark:text-whitish'>Enter collections name</p>}
               getOptionLabel={option => option.name}
               renderOption={(props, option, state) => (
                  <div className="flex justify-between w-96 text-sm capitalize text-dark w-full p-2 dark:text-whitish" {...props}>
                  <span className="flex-1 w-32 break-words pr-3 text-dark dark:text-whitish">{option.name}</span>
                  <span className="text-gray">{option.wordList?.wordCount} words</span>
               </div>
               )}
               onChange={(event, value) => onChangeAutocomplete(value)}
               className="editCollection__autocomplete"
               renderInput={(params) => <TextField {...params}
                  placeholder="Name of Collection"
                  className="editCollection__input"
                  onChange={(e) => onChangeCollectionInput(e)}
                  variant="outlined"
               />}
            />
         </div>

         { error && <div className="absolute bottom-3 left-0 text-center text-xs text-errorRed">Please select collection</div>}

         <div className="mx-auto w-min mt-5">
            <PurpleButtonSmall isLoading={isLoading} text="Save" onClick={onSave} /> 
         </div>

      </section>
   )
}

export default connect(
   null,
   mapDispatchToProps
)(AddToCollection);
