import React, {useEffect, useRef} from "react";
import {connect} from "react-redux";
// import {GoogleLogin as GoogleOldLogin} from "react-google-login";
import AppleLogin from "react-apple-login";
import {useHistory, useLocation} from "react-router-dom";
import {GoogleOAuthProvider, GoogleLogin, useGoogleLogin} from "@react-oauth/google";

import {gapi} from "gapi-script";

// actions
import {openNotification} from "../../actions/notification";
import {currentUser} from "../../actions/currentUser";

// api
import {googleAuth, getUser} from "../../service/requests";

const mapDispatchToProps = (dispatch) => ({
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },
});

const GoogleAppleAuth = (props) => {
  const {openNotification, currentUser, setIsGoogleAuthLoading} = props;
  const location = useLocation();
  const previousRouteRef = useRef(location.state?.from || "/");
  const history = useHistory();
  const clientId = "119326919458-efacc3jlri69hh9erltn4cae3q3kh8mc.apps.googleusercontent.com";

  useEffect(() => {
    gapi.load("client: auth2", () => {
      gapi.auth2.init({clientId: clientId});
    });
  }, []);

  const responseGoogle = async (tokenId) => {
    if (tokenId && tokenId) {
      setIsGoogleAuthLoading(true);
      try {
        const res = await googleAuth({token: tokenId});

        if (res.data.ok) {
          console.log("res", res.data.user);
          localStorage.setItem("accessToken", res.data.token);

          if (res.data.newUser) {
            history.push({
              pathname: "/chooseRole",
              state: {isGoogleAuth: true},
            });
          } else {
            try {
              const resUser = await getUser();
              if (resUser.data.user) {
                currentUser({ user: resUser.data.user });

                const invitationCode = localStorage.getItem("invitationCode");
                if (invitationCode) {
                  setTimeout(() => {
                    history.push(`/invite/${invitationCode}`);
                  }, 0);
                } 

                const previousRoute = previousRouteRef.current;
                if (previousRoute.startsWith("/collection/")) {
                  setTimeout(() => {
                    history.push(previousRoute);
                  }, 0);
                } else {
                  history.push("/");
                }
              }
            } catch (e) {
              console.log("e", e);
            }
          }
        }
      } catch (error) {
        console.log(error);
        setIsGoogleAuthLoading(false);
        openNotification({text: 'Something went wrong', notificationType: "error"});

        if (error &&  error.response?.data) {
          openNotification({text: error.response.data.message, notificationType: "error"});
        }
      }
    }
  };


const login = useGoogleLogin({
  flow: 'auth-code',
  scope: 'email',
  onSuccess: async (codeResponse) => {
      console.log('codeResponse', codeResponse);
      responseGoogle(codeResponse.code);
  },
  onError: errorResponse => console.log(errorResponse),
});


  return (
    <div className="flex justify-between my-5 sm:my-10">
      {/* <GoogleOldLogin
        clientId={clientId}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
        redirectUri="http://localhost:3000/login"
        // scope = "https://www.googleapis.com/auth/userinfo.email"
        render={(renderProps) => (
          <button className="auth__googleBtn" onClick={renderProps.onClick} disabled={renderProps.disabled}>
            <img src="images/ico/google.svg" />
            Login with Google
          </button>
        )}
      /> */}

      <button className="auth__googleBtn"  onClick={() => login()}>
        <img src="images/ico/google.svg" />
          Login with Google
      </button>

      {/* <AppleLogin
        clientId="com.r-words.rwords"
        teamId="82GV89PQXW"
        keyId="WW93PWANS7"
        redirectURI="https://example.com/auth"
        scope="name email"
        // redirectURI="https://redirectUrl.com"
        render={(renderProps) => (
          <button className="auth__appleBtn" onClick={renderProps.onClick} disabled={renderProps.disabled}>
            <img src="images/ico/apple.svg" />
            Login with Apple
          </button>
        )}
      /> */}
    </div>
  );
};

export default connect(null, mapDispatchToProps)(GoogleAppleAuth);
