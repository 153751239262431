import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../service/requests";
import { getUser, getCollections } from "../../service/requests";
import { DisableButton, PurpleButton, PurpleButtonWithLoading } from "../../components/buttons/buttons";
import CircularProgress from '@mui/material/CircularProgress';
import "../signUp/signUp.scss";
import Switch from "@mui/material/Switch";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import FormControlLabel from '@mui/material/FormControlLabel';
import Lottie from 'lottie-react';

// animated logo
import AnimatedLogo from '../../img/logorwAnimatedLottie.json';

import { currentUser } from "../../actions/currentUser";
import { setCollections } from "../../actions/collections";
import { setIsNotFound } from "../../actions/pages";
import GoogleAppleAuth from "../../components/googleAppleAuth/googleAppleAuth";
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const mapStateToProps = (state) => ({
  ...state.currentUser,
  ...state.themeMode,
});

const mapDispatchToProps = (dispatch) => ({
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },
  setCollections: (payload) => {
    return dispatch(setCollections(payload));
  },
  setIsNotFound: (payload) => {
    return dispatch(setIsNotFound(payload));
  },
});

const LogIn = (props) => {

  const location = useLocation();
  const previousRouteRef = useRef(location.state?.from || "/");
  const passwordEl = useRef(null);
  const { currentUser, setCollections, themeMode, setIsNotFound } = props;
  const root = window.document.documentElement;
  const [userData, setUserData] = useState({ email: "", password: "" });
  const history = useHistory();
  const [rememberMe, setRememberMe] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleAuthLoading, setIsGoogleAuthLoading] = useState(false);
  const [isPasswordFocus, setIsPasswordFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const handleChange = (e) => {
    setErrorMessage(null);
    setUserData({
      ...userData,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    if (userData.email != "") setEmailError(false);
    if (userData.password != "") setPasswordError(false);
  }, [userData]);

  // validation
  const validate = () => {
    let emailError = "";
    let passwordError = "";

    if (!userData.email) {
      emailError = "Email cannot be blank";
    }

    if (!userData.password) {
      passwordError = "Password cannot be blank";
    }

    if (emailError || passwordError) {
      setEmailError(emailError);
      setPasswordError(passwordError);
      return false;
    }
    return true;
  };

  const logIn = async () => {
    const isValid = validate();
    setErrorMessage("");

    if (isValid && !isLoading) {
      setIsLoading(true);
      let resLogin;
      let resUser;

      try {
        resLogin = await loginUser(userData);
        localStorage.setItem("accessToken", resLogin.data.token);
        resUser = await getUser().catch(console.log);
        currentUser({ user: resUser.data.user });
        setIsNotFound({isNotFound: false});
      } catch (error) {
        setErrorMessage("Wrong email or password");
        setIsLoading(false);
        console.log(error);
      }

      if (!resLogin) {
        return;
      }

      if (!resUser) {
        // set error
        localStorage.removeItem("accessToken");
        history.push("/login");
        return;
      }

      const invitationCode = localStorage.getItem("invitationCode");
      if (invitationCode) {
        setTimeout(() => {
          history.push(`/invite/${invitationCode}`);
        }, 0);
      } 
      
      const previousRoute = previousRouteRef.current;
      if (previousRoute.startsWith("/collection/")) {
        setTimeout(() => {
          history.push(previousRoute);
        }, 0);
      } else {
        history.push("/");
      }
    }
  };

  const loginOnEnter = (event) => {
    if (event.key === "Enter") {
      if (userData.email.length > 0 && userData.password.length > 0) {
        logIn();
      } else if (userData.email.length > 0 && userData.password.length === 0) {
        setIsPasswordFocus(true);
        passwordEl.current.focus();
      }
    }
  };

  return (
    <div className="authWrapper">
      <Lottie className="w-28 sm:w-32 mx-auto" animationData={AnimatedLogo} loop={false} autoPlay={false} />
      <div className="fadeIn relative w-full">
        {isGoogleAuthLoading && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <CircularProgress size={100} />
          </div>
        )}

        <div className={isGoogleAuthLoading ? "opacity-10 pointer-events-none" : ""}>
          <div className="flex flex-col-reverse mt-10 relative">
            <input
              type="email"
              id="email"
              autoFocus={true}
              className={emailError ? "auth__input--error" : "auth__input"}
              onChange={(e) => handleChange(e)}
              onKeyPress={(e) => loginOnEnter(e)}
            />
            <label for="email" className="">
              Email
            </label>
            <div className="auth__textError">{emailError}</div>
            {errorMessage && <div className="absolute top-0 right-0 text-xs text-redError">{errorMessage}</div>}
          </div>
          <div className="flex flex-col-reverse mt-4 sm:mt-7 relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              className={passwordError ? "auth__input--error pr-14" : "auth__input pr-14"}
              onChange={(e) => handleChange(e)}
              // onKeyPress={(e) => loginOnEnter(e)}
              autoFocus={isPasswordFocus}
              ref={passwordEl}
            />
            <label for="password" className="">
              Password
            </label>
            <div className="auth__textError">{passwordError}</div>
            <div className="w-min absolute right-1 bottom-1">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOffIcon className="text-numberGray" /> : <VisibilityIcon className="text-numberGray" />}
              </IconButton>
            </div>
          </div>

          <div className="flex justify-between items-center mt-4 sm:mt-7">
            <div className="flex items-center">
              <Switch checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} name="checkedB" />
              <div
                className="text-darkBlue dark:text-darkModeGray ml-3 text-sm cursor-pointer dark:hover:text-purple"
                onClick={() => setRememberMe(!rememberMe)}
              >
                Remember me?
              </div>
            </div>
            <div
              // className="cursor-pointer text-darkBlue dark:text-darkModeGray text-sm transition dark:hover:text-purple hover:text-purple pointer-events-none"
              className="cursor-pointer text-gray dark:text-darkModeGray text-sm transition dark:hover:text-purple hover:text-purple pointer-events-none"
              onClick={() => history.push("/recoveryPassword")}
            >
              Forgot password?
            </div>
          </div>

          <div className="mx-auto w-full sm:w-max mt-12">
            <div className={errorMessage && "auth__login--error"}>
              <PurpleButtonWithLoading text="Log In" onClick={logIn} isLoading={isLoading} />
            </div>
          </div>

          <GoogleAppleAuth setIsGoogleAuthLoading={(isLoading) => setIsGoogleAuthLoading(isLoading)} />

          <div className="text-sm text-darkBlue dark:text-whitish text-center mt-8">
            Don't have an account?
            <NavLink to="/signUp">
              <span className="font-semibold text-purple ml-2 cursor-pointer transition hover:text-hoverPurple">
                Sign Up
              </span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
