// Popup
export const OPEN_POPUP = "OPEN_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";

// Notification
export const OPEN_NOTIFICATION = "OPEN_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

// Current User
export const CURRENT_USER = "CURRENT_USER";

// Collections
export const SET_COLLECTIONS = "SET_COLLECTIONS";
export const ADD_COLLECTION = "ADD_COLLECTION";
export const UPDATE_COLLECTION = "UPDATE_COLLECTION";
export const DELETE_COLLECTION = "DELETE_COLLECTION";
export const SET_RECENTLY_USED_COLLECTIONS = "SET_RECENTLY_USED_COLLECTIONS";
export const COLLECTIONS_LOADING = "COLLECTIONS_LOADING";
export const COLLECTIONS_ERROR = "COLLECTIONS_ERROR";
export const SET_SORT_VALUE = "SET_SORT_VALUE";

// Tags
export const SET_TAGS = "SET_TAGS";
export const ADD_TAG = "ADD_TAG";
export const UPDATE_TAG = "UPDATE_TAG";
export const DELETE_TAG = "DELETE_TAG";

// Selection Window
export const OPEN_SELECTION_WINDOW = "OPEN_SELECTION_WINDOW";
export const CLOSE_SELECTION_WINDOW = "CLOSE_SELECTION_WINDOW";

// Classes
export const SET_CLASSES = "SET_CLASSES";
export const ADD_CLASS = "ADD_CLASS";
export const UPDATE_CLASS = "UPDATE_CLASS";
export const DELETE_CLASS = "DELETE_CLASS";
export const UPSERT_COLLECTION_TO_CLASS = "UPSERT_COLLECTION_TO_CLASS";

// Search
export const SET_SEARCH_WORD = "SET_SEARCH_WORD";
export const CHECK_LAST_ELEM = "CHECK_LAST_ELEM";
export const SET_PAGE = "SET_PAGE";
export const SET_PAGE_NAME = "SET_PAGE_NAME";
export const SET_DATA_LOADER = "SET_DATA_LOADER";

// Words
export const SET_WORDS = "SET_WORDS";

// Speaker Languages
export const SET_SPEAKER_LANGS = "SET_SPEAKER_LANGS";


// Theme Mode
export const SET_THEME_MODE = "SET_MODE";

// Pages
export const SET_IS_NOT_FOUND = "SET_IS_NOT_FOUND";

// Languages
export const SET_LANGUAGES = "SET_LANGUAGES";

// Menu 
export const TOGGLE_MENU = "TOGGLE_MENU";
