import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import './addWord.scss'
import ButtonBase from '@mui/material/ButtonBase';
import SendIcon from '@mui/icons-material/Send';
import LanguagesSelectorFromTo from './../../languageSelector/languagesSelectorFromTo';
import ReactCountryFlag from "react-country-flag";
import { saveWord, translateWord, getCollectionById } from '../../../service/requests';
import { closePopup, openPopup } from '../../../actions/popup';
import { openNotification } from '../../../actions/notification';
import { updateCollection } from '../../../actions/collections';
import { getNativeLang } from '../../../utils/utils';


const mapStateToProps = state => ({
   ...state.currentUser
});

const mapDispatchToProps = dispatch => ({
   openPopup: (payload) => {
      return dispatch(openPopup(payload));
   },
   closePopup: () => {
      return dispatch(closePopup());
   },
   openNotification: (payload) => {
      return dispatch(openNotification(payload));
   },
   updateCollection: (payload) => {
      return dispatch(updateCollection(payload));
   }
})

const AddWord = (props) => {

   const {
      info, 
      openPopup,
      user,
      closePopup
   } = props;

   const collectionId = info.id;
   const onAddedWordEvent = info.onAddedWordEvent;
   const [wordData, setWordData] = useState();
   const [isLoaded, setIsLoaded] = useState(false);
   const [isSaveBtnShown, setIsSaveBtnShown] = useState(false);
   const [error, setError] = useState('');
   const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(false);
   const [word, setWord] = useState('');
   const [isLangSelectorUsed, setIsLangSelectorUsed] = useState(false);
   const [fromLang, setFromLang] = useState();
   const [toLang, setToLang] = useState(user.nativeLang);

   useEffect(() => {
      if(!info.classLang) return;
         setToLang(info.classLang)

      if(!info.classLearnLang) return;
         setFromLang(info.classLearnLang)
   }, [])

   useEffect(() => {
      if(isLangSelectorUsed){
         onTranslate();
      } 
   }, [fromLang, toLang])
   
   const onTranslate = async (e) => {
      if(e) {
         if (e.charCode !== 13) return;
         if (e.charCode === 13 && !word) {
            setError('Please enter the word');
            return
         }
      }

      if(!word) {
         setError('Please enter the word');
         return
      }

      let trimmedWord = word.replace(/  +/g, ' ').trim();
      if(trimmedWord.split(" ").length - 1 > 5) {
         setError('You can not add big sentence');
         return
      }

      setIsLoaded(true);
      setError('');

      const dataForSend = {
         word: word.replace(/  +/g, ' ').trim(),
         target: toLang,
         from: fromLang === null || fromLang === 'auto' ? undefined : fromLang,
      }

      try {
         const res = await translateWord(dataForSend);
         setIsLoaded(false);
         const data = res.data;
         setIsSaveBtnShown(true);
         setWordData(data);

      } catch(e) {
         setIsLoaded(false);
         setError('Please try again, server error');
         console.log('e', e)
     }

   }

   const onSaveWord = async () => {
      setIsLoaded(true);
      setIsSaveBtnShown(false);

      if (!wordData) {
         setError('Enter the word and press enter button');
         return;
      }

      try {
         const res = await saveWord({ wordData, collectionId });
         onAddedWordEvent(res.data.word);
         closePopup();
       }
 
       catch (error) {
         console.log(error)
         setIsDisabledSaveBtn(false)
       }

       openNotification({text: 'Word added successfully', notificationType: 'success' });
   }

   const openAddWordTeacherPopup = () => {
      openPopup({currentPopup: 'AddWordTeacher', info: {wordData, collectionId, onAddedWordEvent}});
   }

   const showSaveBtn = () => {
      if(isDisabledSaveBtn) {
         return (
            <ButtonBase>
               <div className="addWord__btnSave--disabled">Save</div>
            </ButtonBase>
         )
      } else {
         return (
            <ButtonBase>
            <div className="addWord__btnSave">
                  <p onClick={onSaveWord}>Save</p>
                  <div className='addWord__btnEdit' onClick={openAddWordTeacherPopup}>
                     <img src="/images/ico/edit.svg" alt="edit icon" />
                  </div>
               </div>
            </ButtonBase>
         )
      }
   }

   const setLang = (fromOrTo, lang) => {
      if(fromOrTo === 'from') {
         setFromLang(lang)
      } else {
         setToLang(lang)
      }
      setIsLangSelectorUsed(true)
   }

   const checkComplianceOfInformation = () => {
      if(wordData) {
         setWordData();
         setIsSaveBtnShown(false);
      }
   }

   const isNumber = () => {
      let regExp = /^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\d]*$/g;
      let isMatch = regExp.test(word);
      return isMatch
   }

   return (
      <section className="relative">
         <input autoFocus className="border-lightGray border dark:border-darkModeSeparationLine shadow rounded-lg py-4 pl-3 pr-56 text-dark dark:text-white mt-1 outline-none bg-white dark:bg-darkModeDark w-full focus:border-lightPurple" onChange={(e) => {setWord(e.target.value); checkComplianceOfInformation()}} onKeyPress={(e) => !isNumber() && onTranslate(e)}></input>
         {isLoaded && 
            <ButtonBase>
               <div className="addWord__btn"><div className="loader__circle"></div></div>
            </ButtonBase>
         }
         <div className={isSaveBtnShown ? 'addWord__langContainerWrapper--wide' : 'addWord__langContainerWrapper'}>
            <LanguagesSelectorFromTo classLearnLang={info.classLearnLang} classLang={info.classLang} onInputChange={() =>  setIsSaveBtnShown(false)} setLang={(fromOrTo, lang) => setLang(fromOrTo, lang)} />
         </div>

         {!isLoaded && isSaveBtnShown && showSaveBtn() }
         {!isLoaded && !isSaveBtnShown &&
            <ButtonBase>
               <div className={word.length && !isNumber() ? "addWord__btn" : "addWord__btn--disabled" }
                  onClick={() => onTranslate()}><SendIcon fontSize="small" className="text-white" /></div>
            </ButtonBase>
         }

         {wordData &&
            <div className="bg-white dark:bg-darkModeDark text-dark dark:text-whitish rounded-lg py-3 px-3 mt-2 shadow">
               <div className="flex justify-between items-start">
                  <div className="text-lg font-semibold">{wordData.translatedText}</div>
                  <div className="flex flex-col items-end">
                     {wordData.detectedLang &&
                     <div className='flex items-center px-5'>
                                 { getNativeLang(wordData.detectedLang) && 
                                    <>
                                       <ReactCountryFlag countryCode={getNativeLang(wordData.detectedLang)?.flag} svg />
                                       <div className='ml-2 text-sm'>{getNativeLang(wordData.detectedLang)?.name}</div>
                                    </>
                                 }
                              </div>
}
                  <div>
                     {wordData.partsOfSpeech &&
                     <div className="px-5">
                        {wordData.partsOfSpeech.filter(x => x != null).map((x, index) => <span className="text-gray italic text-sm font-light">{x}{index === wordData.partsOfSpeech.filter(x => x != null).length - 1 ? '': ','} </span>)}
                        </div>
                     }
                  </div>
                     </div>
               </div>

            {(wordData.definitions && wordData.definitions.length > 0) &&
               <>
                  <div className="text-gray mt-5 font-light">Definitions</div>
                  {wordData.definitions.map((definition) => <div>{definition.charAt(0).toUpperCase() + definition.slice(1)}</div>)}
               </>
            }

            {(wordData.synonyms && wordData.synonyms.length > 0 ) &&
               <>
                  <div className="text-gray mt-5 font-light">Synonyms</div>
                  {wordData.synonyms.map((syn, i, arr) => {
                        return i === arr.length - 1 ? <span className="">{syn}</span> : <span className="">{`${syn}, `}</span>
                  })
                  }
               </>
            }

            {(wordData.examples && wordData.examples.length > 0) &&
               <>
                  <div className="text-gray mt-5 font-light">Examples</div>
                  {wordData.examples.map((example) => <div>{example}</div>)}
               </>
            }

            </div>
         }

         <div className="text-xs text-redError mt-2 mb-2">{error}</div>
      </section>
   )
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(AddWord);