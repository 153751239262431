import {Suspense, useEffect, useState} from "react";
import {connect} from "react-redux";
import {Route, Switch, Redirect, useHistory,} from "react-router-dom";
import routes from "./Routes"; // Route list
import NoFoundPage from "./pages/noFoundPage/noFoundPage";
import {openNotification} from "./actions/notification";

const mapStateToProps = (state) => ({
  ...state.pages,
});

const mapDispatchToProps = (dispatch) => ({
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
});

const ProtectedRoutes = (props) => {

  const {openNotification, user, isNotFound} = props;
  const history = useHistory();
  const [allRoutes, setAllRoutes] = useState(routes);

  useEffect(() => {
    if (user?.role === "teacher") {
      const filteredRoutes = allRoutes.filter((x) => !x.onlyStudent);
      setAllRoutes(filteredRoutes);
    }
  }, []);

  useEffect(() => {
    console.log('isNotFound', isNotFound);
    if(isNotFound) {
      history.push("/404");
    }
  }, [isNotFound]);

  return (
    <Suspense
      fallback={
        <div className="animatedLoaderLogoWrapper">
          <img className="animatedLoaderLogo" src="/images/logo.svg" />
        </div>
      }
    >
      <Switch>
        {allRoutes.map(({component: Component, path, exact, onlyStudent}) => {
          // if (onlyStudent && user.role === "teacher") {
          //   openNotification({
          //     text: "Only for student!",
          //     notificationType: "info",
          //   });
          // }
          return (
            <Route path={`/${path}`} key={path} exact={exact}>
              <Component />
            </Route>
          );
        })}
        <Route path={`/404`} component={NoFoundPage} />
        <Route component={NoFoundPage} />
      </Switch>
    </Suspense>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoutes);
